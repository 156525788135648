<style>
thead {
  background-color: #3d3d3d;
}

.v-data-table
  .v-table__wrapper
  > table
  > thead
  > tr
  > th.v-data-table__th--sortable:hover,
.v-data-table
  .v-table__wrapper
  > table
  tbody
  > tr
  > th.v-data-table__th--sortable:hover {
  cursor: unset;
  color: white;
}

.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > th {
  font-weight: 500;
  user-select: none;
  text-align: start;
  color: white;
}

textarea {
  height: 80px;
}
</style>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 15px; font-weight: 600; border-bottom: 1px solid">
      Datos Generales
    </h2>
    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label><b></b>Tipo Solicitud:</label>

        <v-autocomplete
          v-model="tipoSol"
          :items="lstTipoSolicitud"
          variant="solo"
          item-title="id"
          autocomplete="off"
          item-value="nombre"
          return-object
          @update:modelValue="cambioRetBodega"
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Fecha Solicitud:</label>

        <v-text-field
          pa-1
          hide-details
          ma-0
          v-model="date"
          type="date"
          variant="solo"
          color="primary"
          autocomplete="off"
          required
          disabled
        />
      </div>

      <div class="w-in">
        <label>Ingresado Por:</label>

        <v-text-field
          variant="solo"
          pa-1
          ma-0
          v-model="solPorNombre"
          autocomplete="off"
          readonly
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label> Rut Ingresado Por: </label>
        <v-text-field
          variant="solo"
          pa-1
          ma-0
          v-model="solPorId"
          readonly
          autocomplete="off"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Empresa:</label>

        <v-autocomplete
          v-model="empresaSel"
          :items="lstEmpresas"
          variant="solo"
          item-title="displayName"
          item-value="id"
          autocomplete="off"
          @update:modelValue="selectCompany"
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-autocomplete
          v-model="departamentoSel"
          :items="lstDepartamento"
          variant="solo"
          item-title="codeDisplayName"
          item-value="id"
          clearable
          autocomplete="off"
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Solicitante:</label>

        <v-autocomplete
          v-model="solicitanteSel"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          hide-details
          ma-0
          item-title="rutDisplayName"
          item-value="personId"
          clearable
          autocomplete="off"
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Compra'">
        <label>Proveedor:</label>

        <v-autocomplete
          v-model="vendorSel"
          :items="lstVendors"
          variant="solo"
          item-title="numberDisplayName"
          item-value="id"
          clearable
          autocomplete="off"
          hide-details
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Compra'">
        <label>Comprador:</label>

        <v-autocomplete
          v-model="compradorSel"
          :items="lstCompradores"
          variant="solo"
          item-title="rutDisplayName"
          item-value="rut"
          clearable
          hide-details
          autocomplete="off"
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Retiro Bodega'">
        <label>Cod. Bod:</label>

        <v-autocomplete
          variant="solo"
          :items="lstBodegas"
          hide-details
          pa-1
          ma-0
          clearable
          item-title="codeDisplayName"
          item-value="id"
          autocomplete="off"
          v-model="codigoBodega"
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in">
        <label>Observación:</label>

        <v-textarea
          variant="solo"
          pa-1
          ma-0
          v-model="obs"
          hide-details
          autocomplete="off"
        >
        </v-textarea>
      </div>
    </div>
  </div>

  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="font-weight: 600">Detalle Solicitud</h2>

    <div class="w-in">
      <label style="font-size: 16px">Busca Detalle:</label>

      <v-combobox
        style="font-size: 16px; margin-bottom: 10px"
        pa-1
        ref="mySearch"
        :loading="loading"
        :items="lstItems"
        :hide-no-data="false"
        v-model="item"
        item-title="numberDisplayName"
        item-value="id"
        variant="solo"
        dense
        hide-details
        autocomplete="off"
        clearable
        label="Busca un Material/Servicio"
        no-data-text="No se encontro Material/Servicio"
        @update:search="BuscarMaterial"
        return-object
      >
      </v-combobox>
    </div>

    <v-data-table
      v-if="tipoSol != null && tipoSol.id == 'Compra'"
      :headers="headers"
      :items="lstDetalle"
    >
      <template v-slot:item.cantidad="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          autocomplete="off"
          pa-1
          ma-0
          v-model="item.cantidad"
          @keypress="filterNumber(event)"
          @update:modelValue="calcularTotalCantidad(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.precio="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          autocomplete="off"
          pa-1
          ma-0
          v-model="item.precio"
          @keypress="filterOnlyNumber(event)"
          @update:modelValue="calcularTotalCantidad(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.total="{ item }">
        {{ formatTotal(item.total != null ? item.total : null) }}
      </template>

      <template v-slot:item.cecoId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          hide-details
          autocomplete="off"
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.cecoId"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.proyectoId="{ item }">
        <v-autocomplete
          v-model="item.proyectoId"
          pa-1
          autocomplete="off"
          ma-0
          hide-details
          :items="lstProyectos"
          variant="outlined"
          density="compact"
          item-title="codeDisplayName"
          item-value="id"
          clearable
          :return-object="false"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.oTMantencion="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          autocomplete="off"
          v-model="item.oTMantencion"
        >
        </v-text-field>
      </template>

      <template v-slot:item.codigoBodega="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstBodegas"
          density="compact"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.codigoBodega"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipoSol != null && tipoSol.id == 'Retiro Bodega'"
      mobile-breakpoint="0"
      :headers="headersRetiro"
      :items="lstDetalle"
    >
      <template v-slot:item.tipo="{ item }">
        {{ item.tipo }}
      </template>

      <template v-slot:item.cantidad="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          autocomplete="off"
          v-model="item.cantidad"
          @keypress="filterNumber(event)"
          @update:modelValue="formatoCantidad(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.cecoId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          autocomplete="off"
          ma-0
          hide-details
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          clearable
          v-model="item.cecoId"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.proyectoId="{ item }">
        <v-autocomplete
          v-model="item.proyectoId"
          pa-1
          ma-0
          hide-details
          autocomplete="off"
          :items="lstProyectos"
          variant="outlined"
          density="compact"
          item-title="codeDisplayName"
          item-value="id"
          clearable
          :return-object="false"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.oTMantencion="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          v-model="item.oTMantencion"
        >
        </v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipoSol.id === 'Rendicion'"
      :headers="headersRendicion"
      :items="lstDetalle"
    >
      <template v-slot:item.tipoDoc="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstTipoDoc"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="nombre"
          autocomplete="off"
          item-value="id"
          :return-object="false"
          v-model="item.tipoDoc"
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.nroDoc="{ item }">
        <div
          v-if="item.tipoDoc == 'Sin Documento' /* || item.TipoDoc == 'Vale' */"
        >
          <v-text-field
            pa-1
            ma-0
            v-model="item.nroDoc"
            variant="outlined"
            autocomplete="off"
            color="primary"
            required
            density="compact"
            hide-details
            readonly
          />
        </div>

        <div v-else>
          <v-text-field
            pa-1
            ma-0
            v-model="item.nroDoc"
            variant="outlined"
            color="primary"
            required
            autocomplete="off"
            density="compact"
            hide-details
          />
        </div>
      </template>

      <template v-slot:item.fechaDoc="{ item }">
        <v-text-field
          pa-1
          ma-0
          v-model="item.fechaDoc"
          type="date"
          autocomplete="off"
          variant="outlined"
          color="primary"
          required
          density="compact"
          hide-details
        />
      </template>

      <template v-slot:item.proveedorId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstVendors"
          density="compact"
          autocomplete="off"
          pa-1
          ma-0
          hide-details
          item-title="numberDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.proveedorId"
          clearable
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.descGasto="{ item }">
        <v-text-field
          pa-1
          ma-0
          v-model="item.descGasto"
          variant="outlined"
          autocomplete="off"
          color="primary"
          required
          density="compact"
          hide-details
        />
      </template>

      <template v-slot:item.monto="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          autocomplete="off"
          v-model="item.monto"
          @keypress="filterOnlyNumber(event)"
          @update:modelValue="rendicionTotal(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.cecoId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          autocomplete="off"
          hide-details
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.cecoId"
          clearable
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.proyectoId="{ item }">
        <v-autocomplete
          v-model="item.proyectoId"
          pa-1
          ma-0
          :items="lstProyectos"
          variant="outlined"
          item-title="codeDisplayName"
          item-value="id"
          density="compact"
          clearable
          autocomplete="off"
          :return-object="false"
          hide-details
        >
        </v-autocomplete>
      </template>

      <template v-slot:item.oTMantencion="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          autocomplete="off"
          v-model="item.oTMantencion"
        >
        </v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <div
      v-if="tipoSol.id != 'Retiro Bodega'"
      style="
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-end;
      "
    >
      Total: {{ formatTotal(total) }}
    </div>

    <div class="d-flex flex-wrap">
      <div class="w-in">
        <v-file-input
          pa-1
          ma-0
          hide-details
          v-model="selectedFiles"
          multiple
          label="Selecciona archivos"
          variant="solo"
          autocomplete="off"
          @update:modelValue="addFiles"
        >
        </v-file-input>

        <v-chip closable v-for="f in file" v-bind:key="f.name">
          {{ f.name }}
        </v-chip>
      </div>

      <div class="w-69 d-flex mt-3" style="justify-content: end">
        <v-btn
          style="
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-right: 5px;
            display: flex;
          "
          density="default"
          v-on:click="guardarSolicitud()"
          >Guardar</v-btn
        >

        <v-btn
          style="
            background-color: #10510e;
            padding: 15px;
            border-radius: 15px;
            color: white;
            display: flex;
          "
          density="default"
          v-on:click="crearSolicitud()"
          >Crear Solicitud</v-btn
        >
      </div>
    </div>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Item </v-card-title>
      <v-card-text>
        Esta seguro que desea item el material `{{
          itemToDelete.codigoMaterial
        }}` ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" @click="deleteMaterial(itemToDelete)">Si</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      color="#a61a2f"
      size="50"
      :width="5"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import axios from "axios";
import { useCookies } from "vue3-cookies";
import { useRouter, useRoute } from "vue-router";
import swal from "sweetalert2";
import { PublicClientApplication } from "@azure/msal-browser";
import AzureService from "../services/AzureService";
import currency from "currency.js";

import type { TipoSolicitud } from "../types/tipoSolicitud";
import type { Empleado } from "../types/empleado";
import type { Company } from "../types/company";
import type { Departamento } from "../types/departamento";
import type { Vendors } from "../types/vendors";
import type { Item } from "../types/item";
import type { SolicitudDetalle } from "../types/solicitudDetalle";
import type { Proyecto } from "../types/proyecto";
import type { Location } from "../types/location";
import type { TipoDocumento } from "../types/tipoDocumento";
import type { SolicitudEncabezado } from "../types/SolicitudEncabezado";
import type { SolicitudCompra } from "../types/solicitudCompra";
import useEmpresas from "../composables/useEmpresa";
import useDepartamentos from "../composables/useDepartamento";
import useComprador from "../composables/useComprador";
import useSolicitante from "../composables/useSolicitante";
import useBodegas from "../composables/useBodega";
// import useEmpleado from "../composables/useEmpleado";
import useCeco from "../composables/useCeco";
import useVendors from "../composables/useVendors";
// import { fi } from "vuetify/locale";

const { cookies } = useCookies();
const store = useStore(key);
const router = useRouter();
const route = useRoute();

const { getCompanies, lstEmpresas } = useEmpresas();
const { getDepartamentos, lstDepartamento } = useDepartamentos();
const { getSolicitantes, lstSolicitantes } = useSolicitante();
const { getVendors, lstVendors } = useVendors();
const { getEmpleadoComprador, lstCompradores } = useComprador();
const { getBodegas, lstBodegas } = useBodegas();
const { getCeco, lstCeco } = useCeco();

let $msalInstance: PublicClientApplication;

let dialogDelete: Ref<boolean> = ref(false);
let itemToDelete: SolicitudDetalle = {};

let idSolicitud: string = "";
let isLoading = ref(false);
let total: Ref<number> = ref(0);

let lstTipoSolicitud: TipoSolicitud[] = [
  { id: "Compra", nombre: "Compra" },
  { id: "Retiro Bodega", nombre: "Retiro Bodega" },
  { id: "Rendicion", nombre: "Rendicion" },
];

let date: string = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

let tipoSol: Ref<TipoSolicitud> = ref(lstTipoSolicitud[0]);
let empresaSel: Ref<Company | null> = ref(null);
let departamentoSel: Ref<Departamento | null> = ref(null);
let solicitanteSel: Ref<Empleado | null> = ref(null);
let vendorSel: Ref<Vendors | null> = ref(null);
let compradorSel: Ref<Empleado | null> = ref(null);
let codigoBodega: Ref<Location | null> = ref(null);
let obs: Ref<string> = ref("");
let lstItems: Ref<Item[]> = ref([]);
let item: Ref<Item | string | null> = ref(null);
let loading: boolean = false;
let detContador: number = 1;
let guid: string = "";
let lstProyectos: Ref<Proyecto[]> = ref([]);
const lstDetalle = reactive<SolicitudDetalle[]>([]);
let selectedFiles: Ref<File[]> = ref([]);
let file: Ref<File[]> = ref([]);
let solPorId: Ref<string> = ref("");
let solPorNombre: Ref<string> = ref("");
let autorizador: Ref<string> = ref("");
let usuario: Ref<Empleado | null> = ref(null);

let headers: any = [
  { title: "Id", key: "id", width: "25px" },
  {
    title: "Cod",
    key: "codigoMaterial",
    align: "center",
    width: "120px!important",
  },
  {
    title: "Material/Servicio",
    key: "material",
    align: "center",
    width: "300px",
  },
  { title: "UN", key: "unidad", align: "center" },
  {
    title: "Cantidad",
    key: "cantidad",
    align: "center",
    width: "120px!important",
  },
  { title: "Precio", key: "precio", align: "center", width: "150px!important" },
  { title: "Total", key: "total", align: "center", width: "150px!important" },
  { title: "Ceco", key: "cecoId", align: "start", width: "250px!important" },
  {
    title: "Proyecto",
    key: "proyectoId",
    align: "center",
    width: "200px!important",
  },
  {
    title: "Bodega",
    key: "codigoBodega",
    align: "center",
    width: "200px!important",
  },
  {
    title: "OT Mantención",
    key: "oTMantencion",
    align: "center",
    width: "50px",
  },
  { title: "", key: "actions", align: "center", width: "25px" },
];

let headersRetiro: any = [
  { title: "Id", key: "id", width: "25px" },
  { title: "Cod", key: "codigoMaterial", align: "center", width: "100px" },
  {
    title: "Material/Servicio",
    key: "material",
    align: "start",
    width: "300px",
  },
  { title: "UN", key: "unidad", align: "center", width: "25px" },
  {
    title: "Cantidad",
    key: "cantidad",
    align: "center",
    width: "120px!important",
  },
  { title: "Ceco", key: "cecoId", align: "start", width: "250px!important" },
  {
    title: "Proyecto",
    key: "proyectoId",
    align: "center",
    width: "200px!important",
  },
  {
    title: "OT Mantención",
    key: "oTMantencion",
    align: "center",
    width: "50px",
  },
  { title: "", key: "actions", align: "center", width: "25px" },
];

let headersRendicion: any = [
  { title: "Id", key: "id", width: "25px" },
  {
    title: "Tipo Docto",
    key: "tipoDoc",
    align: "center",
    width: "220px!important",
  },
  {
    title: "N° Docto",
    key: "nroDoc",
    align: "center",
    width: "100px!important",
  },
  {
    title: "Fecha Docto",
    key: "fechaDoc",
    align: "center",
    width: "200px!important",
  },
  {
    title: "Proveedor",
    key: "proveedorId",
    align: "center",
    width: "200px!important",
  },
  {
    title: "Material/Servicio",
    key: "material",
    align: "center",
    width: "150px",
  },
  {
    title: "Descripción del Gasto",
    key: "descGasto",
    align: "center",
    width: "150px",
  },
  { title: "Ceco", key: "cecoId", align: "center", width: "250px!important" },
  {
    title: "Proyecto",
    key: "proyectoId",
    align: "center",
    width: "200px!important",
  },
  { title: "Monto", key: "monto", align: "center", width: "150px!important" },
  {
    title: "OT Mantención",
    key: "oTMantencion",
    align: "center",
    width: "50px",
  },
  { title: "", key: "actions", align: "center", width: "25px" },
];

let lstTipoDoc: TipoDocumento[] = [
  { id: "Sin Documento", nombre: "Sin Documento" },
  { id: "Boleta", nombre: "Boleta" },
  { id: "Factura", nombre: "Factura" },
  { id: "Vale", nombre: "Vale" },
  { id: "B. Honorarios", nombre: "B. Honorarios" },
];

onMounted(async () => {
  const azureService = new AzureService();
  $msalInstance = new PublicClientApplication(
    azureService.getMsalConfig().value
  );

  store.state.titulo = "Crear Solicitud";

  await getSolicitantes();

  if (cookies.get("username") != "") {
    await obtenerEmpleado(cookies.get("username"));
    // await obtenerEmpleado("fcampos@oxxeanchile.cl");
  }

  guid = getGUID();
  //sendEmail("78361c6d-5339-47e5-b47d-96ff5c75902b");

  if (
    route.params.solicitudId != undefined ||
    route.params.solicitudId != null
  ) {
    getSolicitudCompra();
  } else {
    await getCompanies();
    await getEmpleadoComprador();
  }

  localStorage.removeItem("empresa");
});

async function BuscarMaterial() {
  if (empresaSel.value == null) {
    swal.fire({
      icon: "warning",
      title: "Debe seleccionar una empresa",
      showConfirmButton: true,
    });

    item.value = null;
    return;
  }

  let det: SolicitudDetalle = {};

  if (typeof item.value === "string" || item.value instanceof String) {
    loading = true;

    if (item.value != null && new String(item.value).length > 2) {
      axios
        .get(
          store.state.url +
            "api/Item/BuscadorItem?item=" +
            item.value +
            "&companyId=" +
            empresaSel.value.id,
          {
            headers: {},
          }
        )
        .then((response) => {
          if (response.status == 200) {
            lstItems.value = response.data;
            loading = false;
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
      // .finally(() => (loading = false));
    }
  } else {
    if (item.value != null) {
      if (typeof item.value == "string" || item.value instanceof String) {
        return;
      }

      if (tipoSol.value.id == "Rendicion") {
        det = {
          idItem: item.value.id,
          id: detContador,
          encabezadoId: guid,
          codigoMaterial: item.value.number,
          material: item.value.displayName,
          retiroBodega: false,
          monto: null,
          oTMantencion: null,
          tipo: item.value.itemCategoryCode,
          generalProductPostingGroupCode:
            item.value.generalProductPostingGroupCode,
          cantidad: item.value.type == "Service" ? 1 : null,
        };
      } else {
        if (tipoSol.value.id == "Retiro Bodega") {
          det = {
            idItem: item.value.id,
            id: detContador,
            tipo: item.value.itemCategoryCode,
            encabezadoId: guid,
            codigoMaterial: item.value.number,
            material: item.value.displayName,
            unidad: item.value.baseUnitOfMeasureCode,
            cantidad: item.value.type == "Service" ? 1 : null,
            precio: null,
            total: 0,
            retiroBodega: true,
            oTMantencion: null,
          };
        }

        if (tipoSol.value.id == "Compra") {
          det = {
            idItem: item.value.id,
            id: detContador,
            tipo: item.value.itemCategoryCode,
            encabezadoId: guid,
            codigoMaterial: item.value.number,
            material: item.value.displayName,
            unidad: item.value.baseUnitOfMeasureCode,
            cantidad: item.value.type == "Service" ? 1 : null,
            precio: null,
            total: 0,
            oTMantencion: null,
          };
        }
      }

      if (det != null && lstDetalle != null) {
        lstDetalle.push(det);

        item.value = null;
        detContador++;
      }

      lstItems.value = [];
    }
  }
}

async function obtenerEmpleado(email: string) {
  try {
    isLoading.value = true;

    await axios
      .get(store.state.url + "api/Empleado/GetEmpleadoXEmail?email=" + email, {
        headers: {},
      })
      .then(async (response) => {
        if (response.status == 200) {
          usuario.value = response.data;
          autorizador.value = response.data.rut;

          solPorId.value = response.data.rut;
          solPorNombre.value = response.data.fullName;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function getProyectos() {
  try {
    if (empresaSel.value == null) {
      return;
    }

    await axios
      .get(
        store.state.url +
          "api/Proyecto/GetProyectos?company=" +
          empresaSel.value.id,
        { headers: {} }
      )
      .then((response) => {
        if (response.status == 200) {
          lstProyectos.value = response.data as Proyecto[];
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function getSolicitudCompra() {
  try {
    axios
      .get(
        store.state.url +
          "api/SolicitudCompra/GetSolicitudCompra?solicitudId=" +
          route.params.solicitudId
      )
      .then(async (response) => {
        if (response.status == 200) {
          let solicitud: SolicitudCompra = response.data;

          idSolicitud = solicitud.encabezado.id || "";
          tipoSol.value.id = solicitud.encabezado.tipoSolicitudId || "";
          date = solicitud.encabezado.fechaSolicitud || "";

          await getCompanies();

          if (solicitud.encabezado.companyId != null) {
            empresaSel.value =
              lstEmpresas.value.find(
                (r) => r.id == solicitud.encabezado.companyId
              ) || null;
          }

          if (empresaSel.value != null) {
            await getDepartamentos(empresaSel.value.id);
            await getCeco(empresaSel.value.id);
            await getBodegas(empresaSel.value.id);
            await getVendors(empresaSel.value.id);
            await getProyectos();
          }

          await getSolicitantes();
          await getEmpleadoComprador();

          // if (solicitud.encabezado.departamentoId != null) {
          departamentoSel.value =
            lstDepartamento.value.find(
              (d) => d.id == solicitud.encabezado.departamentoId
            ) || null;
          // }

          solicitanteSel.value =
            lstSolicitantes.value.find(
              (s) => s.rut == solicitud.encabezado.solicitanteId
            ) || null;

          obs.value = solicitud.encabezado.observacion || "";

          vendorSel.value =
            lstVendors.value.find(
              (v) => v.id == solicitud.encabezado.vendorId
            ) || null;

          if (tipoSol.value.id == "Retiro Bodega") {
            codigoBodega.value =
              lstBodegas.value.find(
                (b) => b.id == solicitud.encabezado.codigoBodega
              ) || null;

            for (let i = 0; i < solicitud.detalle.length; i++) {
              solicitud.detalle[i].tipo = solicitud.detalle[i].tipo;
              solicitud.detalle[i].material = solicitud.detalle[i].material;
              solicitud.detalle[i].codigoMaterial =
                solicitud.detalle[i].codigoMaterial;

              solicitud.detalle[i].cantidad = solicitud.detalle[i].cantidad;
              solicitud.detalle[i].unidad = solicitud.detalle[i].unidad;

              if (solicitud.detalle[i].cecoId != null) {
                solicitud.detalle[i].cecoId =
                  lstCeco.value.find((c) => c.id == solicitud.detalle[i].cecoId)
                    ?.id || null;
              }

              solicitud.detalle[i].proyectoId =
                lstProyectos.value.find(
                  (p) => p.id == solicitud.detalle[i].proyectoId
                )?.id || null;

              solicitud.detalle[i].retiroBodega =
                solicitud.detalle[i].retiroBodega;
              solicitud.detalle[i].oTMantencion =
                solicitud.detalle[i].oTMantencion;
            }
          }

          if (tipoSol.value.id == "Rendicion") {
            for (let i = 0; i < solicitud.detalle.length; i++) {
              solicitud.detalle[i].tipoDoc = lstTipoDoc.find(
                (t) => t.id == solicitud.detalle[i].tipoDoc
              )?.id;

              solicitud.detalle[i].nroDoc = solicitud.detalle[i].nroDoc;

              if (solicitud.detalle[i].fechaDoc != null) {
                solicitud.detalle[i].fechaDoc = solicitud.detalle[i].fechaDoc;
              }

              if (solicitud.detalle[i].proveedorId != null) {
                solicitud.detalle[i].proveedorId = lstVendors.value.find(
                  (v) => v.id == solicitud.detalle[i].proveedorId
                )?.id;
              }

              solicitud.detalle[i].material = solicitud.detalle[i].material;
              solicitud.detalle[i].descGasto = solicitud.detalle[i].descGasto;

              if (solicitud.detalle[i].cecoId != null) {
                solicitud.detalle[i].cecoId = lstCeco.value.find(
                  (c) => c.id == solicitud.detalle[i].cecoId
                )?.id;
              }

              if (solicitud.detalle[i].proyectoId != null) {
                solicitud.detalle[i].proyectoId = lstProyectos.value.find(
                  (p) => p.id == solicitud.detalle[i].proyectoId
                )?.id;
              }

              solicitud.detalle[i].monto = solicitud.detalle[i].monto;
              rendicionTotal(solicitud.detalle[i]);
            }
          }

          if (tipoSol.value.id == "Compra") {
            compradorSel.value =
              lstCompradores.value.find(
                (c) => c.rut == solicitud.encabezado.comprador
              ) || null;

            for (let i = 0; i < solicitud.detalle.length; i++) {
              solicitud.detalle[i].tipo = solicitud.detalle[i].tipo;
              solicitud.detalle[i].material = solicitud.detalle[i].material;
              solicitud.detalle[i].codigoMaterial =
                solicitud.detalle[i].codigoMaterial;

              solicitud.detalle[i].cantidad = solicitud.detalle[i].cantidad;
              solicitud.detalle[i].unidad = solicitud.detalle[i].unidad;

              if (solicitud.detalle[i].cecoId != null) {
                solicitud.detalle[i].cecoId = lstCeco.value.find(
                  (c) => c.id == solicitud.detalle[i].cecoId
                )?.id;
              }

              solicitud.detalle[i].proyectoId = lstProyectos.value.find(
                (p) => p.id == solicitud.detalle[i].proyectoId
              )?.id;

              solicitud.detalle[i].retiroBodega =
                solicitud.detalle[i].retiroBodega;
              solicitud.detalle[i].oTMantencion =
                solicitud.detalle[i].oTMantencion;

              if (solicitud.detalle[i].proveedorId != null) {
                solicitud.detalle[i].proveedorId = lstVendors.value.find(
                  (v) => v.id == solicitud.detalle[i].proveedorId
                )?.id;
              }

              if (solicitud.detalle[i].codigoBodega != null) {
                solicitud.detalle[i].codigoBodega = lstBodegas.value.find(
                  (b) => b.id == solicitud.detalle[i].codigoBodega
                )?.id;
              }

              solicitud.detalle[i].precio = solicitud.detalle[i].precio;
              solicitud.detalle[i].total = solicitud.detalle[i].total;
            }
          }

          //lstDetalle = solicitud.detalle;

          for (let i = 0; i < solicitud.detalle.length; i++) {
            lstDetalle.push(solicitud.detalle[i]);
          }

          detContador = lstDetalle.length + 1;
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => (isLoading.value = false));
  } catch (error) {
    console.log(error);
  }
}

async function crearSolicitud() {
  if (tipoSol == null) {
    swal.fire({
      icon: "warning",
      title: "Debe seleccionar un tipo de solicitud",
      showConfirmButton: true,
    });
    return;
  }

  if (
    solPorId.value == null ||
    solPorId.value == "" ||
    solPorNombre.value == null ||
    solPorNombre.value == ""
  ) {
    swal.fire({
      icon: "warning",
      title: "Debe haber un ingresado por",
      showConfirmButton: true,
    });
    return;
  }

  if (empresaSel.value == null) {
    swal.fire({
      icon: "warning",
      title: "Debe seleccionar una empresa",
      showConfirmButton: true,
    });
    return;
  }

  if (departamentoSel.value == null) {
    swal.fire({
      icon: "warning",
      title: "Debe seleccionar un departamento",
      showConfirmButton: true,
    });
    return;
  }

  if (solicitanteSel.value == null) {
    swal.fire({
      icon: "warning",
      title: "Debe seleccionar un Solicitante",
      showConfirmButton: true,
    });
    return;
  }

  if (lstDetalle.length == 0) {
    swal.fire({
      icon: "warning",
      title: "Debe agregar al menos un material",
      showConfirmButton: true,
    });
    return;
  }

  if (lstDetalle.some((x) => x.cecoId == null)) {
    swal.fire({
      icon: "warning",
      title: "Debe seleccionar un ceco",
      showConfirmButton: true,
    });
    return;
  }

  var encab: SolicitudEncabezado = {};
  var detalle: SolicitudDetalle[] | undefined = [];

  isLoading.value = true;
  try {
    if (tipoSol.value.id == "Compra" || tipoSol.value.id == "Retiro Bodega") {
      if (lstDetalle.some((x) => x.cantidad == 0 || x.cantidad == null)) {
        swal.fire({
          icon: "warning",
          title: "Debe ingresar una cantidad mayor a 0",
          showConfirmButton: true,
        });
        return;
      }

      encab = {
        id: guid,
        fechaSolicitud: date,
        solicitantePorId: solPorId.value,
        solicitantePorNombre: solPorNombre.value,
        companyId: empresaSel.value.id,
        departamentoId: departamentoSel.value.id,
        observacion: obs.value,
        solicitanteId: solicitanteSel.value.rut,
        solicitanteNombre: solicitanteSel.value.fullName,
        estado: "Creada",
        tipoSolicitudId: tipoSol.value.id,
        // ProyectoId: proyectoSel != null ? proyectoSel.id : null,
      };

      detalle = lstDetalle;

      if (tipoSol.value.id == "Compra") {
        if (vendorSel.value == null) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un proveedor",
            showConfirmButton: true,
          });
          return;
        }

        if (compradorSel.value == null) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un comprador",
            showConfirmButton: true,
          });
          return;
        }

        encab.vendorId = vendorSel.value != null ? vendorSel.value.id : null;
        encab.comprador = compradorSel.value.rut;

        for (let i = 0; i < detalle.length; i++) {
          if (
            detalle[i].codigoMaterial != null &&
            detalle[i].codigoMaterial?.includes("MAT-") &&
            detalle[i].codigoBodega == null
          ) {
            swal.fire({
              icon: "warning",
              title:
                "Se debe seleccionar bodega para el item " +
                detalle[i].material,
              showConfirmButton: true,
            });
            return;
          }

          // var validCantidad = currency(detalle[i].cantidad, {
          //   decimal: ",",
          //   errorOnInvalid: true,
          // });

          // if (isNaN(validCantidad.value)) {
          //   swal.fire({
          //     icon: "warning",
          //     title: "La cantidad no es válida",
          //     showConfirmButton: true,
          //   });
          //   return;
          // }

          // detalle[i].cantidad = validCantidad.value;

          if (detalle[i].precio == null) {
            detalle[i].precio = 0;
          }

          if (
            route.params.solicitudId != undefined ||
            route.params.solicitudId != null
          ) {
            detalle[i].cecoId = detalle[i].cecoId;
            detalle[i].proyectoId = detalle[i].proyectoId;
            detalle[i].codigoBodega = detalle[i].codigoBodega;
          }
        }
      }

      if (tipoSol.value.id == "Retiro Bodega") {
        if (codigoBodega.value == null) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar una bodega",
            showConfirmButton: true,
          });
          return;
        }

        encab.codigoBodega =
          codigoBodega.value.id != null ? codigoBodega.value.id : null;

        if (
          route.params.solicitudId != undefined ||
          route.params.solicitudId != null
        ) {
          encab.codigoBodega =
            codigoBodega != null ? codigoBodega.value.id : null;

          for (let i = 0; i < detalle.length; i++) {
            if (detalle[i].cecoId != null) {
              detalle[i].cecoId = detalle[i].cecoId;
            }

            if (detalle[i].proyectoId != null) {
              detalle[i].proyectoId = detalle[i].proyectoId;
            }
          }
        }
      }

      let solicitud: SolicitudCompra = {
        encabezado: encab,
        detalle: detalle,
      };

      if (
        route.params.solicitudId != undefined ||
        route.params.solicitudId != null
      ) {
        getGUID();

        solicitud.encabezado.id = guid;

        for (let i = 0; i < solicitud.detalle.length; i++) {
          solicitud.detalle[i].encabezadoId = guid;
        }
      }

      // console.log(JSON.stringify(solicitud));
      enviarSolicitud(solicitud);
    } else {
      if (tipoSol.value.id == "Rendicion") {
        if (lstDetalle.some((x) => x.tipoDoc == null)) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un tipo de documento",
            showConfirmButton: true,
          });
          return;
        }

        if (lstDetalle.some((x) => x.fechaDoc == null)) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar una fecha de documento",
            showConfirmButton: true,
          });
          return;
        }

        if (
          lstDetalle.some(
            (x) =>
              x.nroDoc == null &&
              x.tipoDoc != "Sin Documento" &&
              x.tipoDoc != "Vale"
          )
        ) {
          swal.fire({
            icon: "warning",
            title: "Debe ingresar un numero de documento",
            showConfirmButton: true,
          });
          return;
        }

        if (
          lstDetalle.some(
            (x) => x.proveedorId == null && x.tipoDoc == "Factura"
          )
        ) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un proveedor",
            showConfirmButton: true,
          });
          return;
        }

        if (
          lstDetalle.some(
            (x) => x.proveedorId == null && x.tipoDoc == "B. Honorarios"
          )
        ) {
          swal.fire({
            icon: "warning",
            title: "Debe seleccionar un proveedor",
            showConfirmButton: true,
          });
          return;
        }

        if (lstDetalle.some((x) => x.descGasto == null)) {
          swal.fire({
            icon: "warning",
            title: "Debe ingresar una descripción del gasto",
            showConfirmButton: true,
          });
          return;
        }

        if (lstDetalle.some((x) => x.monto == null || x.monto == 0)) {
          swal.fire({
            icon: "warning",
            title: "Debe ingresar un monto",
            showConfirmButton: true,
          });
          return;
        }

        encab = {
          id: guid,
          fechaSolicitud: date,
          solicitantePorId: solPorId.value,
          solicitantePorNombre: solPorNombre.value,
          companyId: empresaSel.value.id,
          departamentoId: departamentoSel.value.id,
          observacion: obs.value,
          solicitanteId: solicitanteSel.value.rut,
          solicitanteNombre: solicitanteSel.value.fullName,
          estado: "Creada",
          tipoSolicitudId: tipoSol.value.id,
        };

        detalle = lstDetalle;

        for (let i = 0; i < detalle.length; i++) {
          detalle[i].deptoId = departamentoSel.value.id;
        }

        let solicitud: SolicitudCompra = {
          encabezado: encab,
          detalle: detalle,
        };

        if (
          route.params.solicitudId != undefined ||
          route.params.solicitudId != null
        ) {
          getGUID();

          solicitud.encabezado.id = guid;

          for (let i = 0; i < solicitud.detalle.length; i++) {
            solicitud.detalle[i].encabezadoId = guid;

            detalle[i].cecoId = detalle[i].cecoId;
            detalle[i].proyectoId = detalle[i].proyectoId;
            detalle[i].proveedorId = detalle[i].proveedorId;
            detalle[i].tipoDoc = detalle[i].tipoDoc;
          }
        }

        // console.log(JSON.stringify(solicitud));
        enviarSolicitud(solicitud);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function enviarSolicitud(solicitud: SolicitudCompra) {
  isLoading.value = true;

  axios
    .post(
      store.state.url + "api/SolicitudCompra/PostSolicitudCompra",
      solicitud
    )
    .then(async (response) => {
      if (response.status == 200) {
        if (file.value.length > 0) {
          await submitFile(solicitud);
        }

        date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);

        swal.fire({
          icon: "success",
          title: "Solicitud creada exitosamente",
          showConfirmButton: false,
          timer: 2000,
        });

        await sendEmail(solicitud.encabezado.id);

        router.push({
          name: "BuscaSolicitud",
        });
      } else {
        swal.fire({
          icon: "error",
          title: "Error al guardar la solicitud: " + response.statusText,
          showConfirmButton: true,
        });
      }
    })
    .catch((error) => {
      swal.fire({
        icon: "error",
        title: "Error al guardar la solicitud: " + error.message,
        showConfirmButton: true,
      });
    })
    .finally(() => (isLoading.value = false));
}

async function guardarSolicitud() {
  var encab: SolicitudEncabezado = {};
  var detalle: SolicitudDetalle[] = [];

  isLoading.value = true;
  try {
    if (
      solPorId.value == null ||
      solPorId.value == "" ||
      solPorNombre.value == null ||
      solPorNombre.value == ""
    ) {
      swal.fire({
        icon: "warning",
        title: "Debe haber un ingresado por",
        showConfirmButton: true,
      });
      return;
    }

    if (tipoSol.value.id == "Compra" || tipoSol.value.id == "Retiro Bodega") {
      encab = {
        id: guid,
        fechaSolicitud: date,
        solicitantePorId: solPorId.value,
        solicitantePorNombre: solPorNombre.value,
        companyId: empresaSel.value != null ? empresaSel.value?.id : null,
        departamentoId:
          departamentoSel.value != null ? departamentoSel.value.id : null,
        observacion: obs.value,
        solicitanteId:
          solicitanteSel.value != null ? solicitanteSel.value.rut : null,
        solicitanteNombre:
          solicitanteSel != null ? solicitanteSel.value?.fullName : null,
        estado: "Guardada",
        tipoSolicitudId: tipoSol.value.id,
      };

      detalle = lstDetalle;

      // for (let i = 0; i < detalle.length; i++) {
      //   // var validCantidad = currency(detalle[i].cantidad, {
      //   //   decimal: ",",
      //   //   errorOnInvalid: true,
      //   // });
      //   // if (isNaN(validCantidad.value)) {
      //   //   swal.fire({
      //   //     icon: "warning",
      //   //     title: "La cantidad no es válida",
      //   //     showConfirmButton: true,
      //   //   });
      //   //   return;
      //   // }
      //   // detalle[i].cantidad = validCantidad.value;

      //   // detalle[i].cantidad = parseFloat(
      //   //   detalle[i]?.cantidad.toString().replace(",", ".")
      //   // );
      // }

      if (tipoSol.value.id == "Compra") {
        encab.vendorId = vendorSel.value != null ? vendorSel.value.id : null;

        if (
          route.params.solicitudId != undefined ||
          route.params.solicitudId != null
        ) {
          for (let i = 0; i < detalle.length; i++) {
            detalle[i].cecoId = detalle[i].cecoId;
            detalle[i].proyectoId = detalle[i].proyectoId;
            detalle[i].codigoBodega = detalle[i].codigoBodega;
          }
        }
      }

      if (tipoSol.value.id == "Retiro Bodega") {
        encab.codigoBodega =
          codigoBodega.value != null ? codigoBodega.value.id : null;

        if (
          route.params.solicitudId != undefined ||
          route.params.solicitudId != null
        ) {
          for (let i = 0; i < detalle.length; i++) {
            detalle[i].cecoId = detalle[i].cecoId;
            detalle[i].proyectoId = detalle[i].proyectoId;
          }
        }
      }

      let solicitud: SolicitudCompra = {
        encabezado: encab,
        detalle: detalle,
      };

      if (
        route.params.solicitudId != undefined ||
        route.params.solicitudId != null
      ) {
        getGUID();

        solicitud.encabezado.id = guid;

        for (let i = 0; i < solicitud.detalle.length; i++) {
          solicitud.detalle[i].encabezadoId = guid;
        }
      }

      // console.log(JSON.stringify(solicitud));
      enviarSolicitudGuardada(solicitud);
    } else {
      if (tipoSol.value.id == "Rendicion") {
        encab = {
          id: guid,
          fechaSolicitud: date,
          solicitantePorId: solPorId.value,
          solicitantePorNombre: solPorNombre.value,
          companyId: empresaSel.value != null ? empresaSel.value.id : null,
          departamentoId:
            departamentoSel.value != null ? departamentoSel.value.id : null,
          observacion: obs.value,
          solicitanteId:
            solicitanteSel.value != null ? solicitanteSel.value.rut : null,
          solicitanteNombre:
            solicitanteSel.value != null ? solicitanteSel.value.fullName : null,
          estado: "Guardada",
          tipoSolicitudId: tipoSol.value.id,
          // proyectoId: proyectoSel.value != null ? proyectoSel.id : null,
        };

        detalle = lstDetalle;

        if (departamentoSel.value != null) {
          for (let i = 0; i < detalle.length; i++) {
            detalle[i].deptoId = departamentoSel.value.id;

            if (
              route.params.solicitudId != undefined ||
              route.params.solicitudId != null
            ) {
              for (let i = 0; i < detalle.length; i++) {
                detalle[i].cecoId = detalle[i].cecoId;
                detalle[i].proyectoId = detalle[i].proyectoId;
                detalle[i].proveedorId = detalle[i].proveedorId;
                detalle[i].tipoDoc = detalle[i].tipoDoc;
              }
            }
          }
        }

        let solicitud: SolicitudCompra = {
          encabezado: encab,
          detalle: detalle,
        };

        if (
          route.params.solicitudId != undefined ||
          route.params.solicitudId != null
        ) {
          getGUID();

          solicitud.encabezado.id = guid;

          for (let i = 0; i < solicitud.detalle.length; i++) {
            solicitud.detalle[i].encabezadoId = guid;
          }
        }

        // console.log(JSON.stringify(solicitud));
        enviarSolicitudGuardada(solicitud);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function enviarSolicitudGuardada(solicitud: SolicitudCompra) {
  isLoading.value = true;

  axios
    .post(
      store.state.url + "api/SolicitudCompra/PostSolicitudCompraGuardada",
      solicitud
    )
    .then(async (response) => {
      if (response.status == 200) {
        if (file.value.length > 0) {
          await submitFile(solicitud);
        }

        date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);

        total.value = 0;

        swal.fire({
          icon: "success",
          title: "Solicitud Guardada Exitosamente",
          showConfirmButton: false,
          timer: 2000,
        });

        router.push({
          name: "BuscaSolicitud",
        });
      } else {
        swal.fire({
          icon: "error",
          title: "Error al guardar la solicitud: " + response.statusText,
          showConfirmButton: true,
        });
        // console.log(response.statusText);
      }
    })
    .catch((error) => {
      swal.fire({
        icon: "error",
        title: "Error al guardar la solicitud: " + error.message,
        showConfirmButton: true,
      });
    })
    .finally(() => (isLoading.value = false));
}

async function sendEmail(idSol: string | undefined) {
  try {
    await refreshToken()
      .then(async () => {
        var token = cookies.get("token");

        await axios
          .post(
            store.state.url + "api/SolicitudCompra/SendEmail?idSol=" + idSol,
            token,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data);
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error: any) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
}

async function refreshToken() {
  try {
    await $msalInstance.initialize();

    var redirectResponse = await $msalInstance.handleRedirectPromise();
    if (redirectResponse !== null) {
      cookies.set("token", redirectResponse.accessToken);
    } else {
      var myAcccount = $msalInstance.getAllAccounts();

      await $msalInstance
        .acquireTokenSilent({
          scopes: ["User.Read", "Mail.Send"],
          account: myAcccount[0],
        })
        .then((response: any) => {
          cookies.set("token", response.accessToken);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log(error);
  }
}

async function submitFile(solicitud: SolicitudCompra) {
  const files = new FormData();

  for (let i = 0; i < file.value.length; i++) {
    files.append("files", file.value[i]);
  }
  //files.append("files", this.file);
  const headers = { "Content-Type": "multipart/form-data" };

  await axios
    .post(store.state.url + "api/SolicitudCompra/UploadMultipleFile", files, {
      headers,
    })
    .then(async (res) => {
      if (res.status == 200) {
        await postFiles(res.data, solicitud.encabezado.id);
        // console.log(tipo);
      } else {
        console.log("Error al cargar archivo, status code:" + res.status);
      }
    })
    .finally(() => (isLoading.value = false));
}

async function postFiles(files: File, solId: string | undefined) {
  await axios
    .post(
      store.state.url + "api/SolicitudCompra/PostFiles?solicitudId=" + solId,
      files,
      { headers: {} }
    )
    .then((response) => {
      if (response.status == 200) {
        console.log("Archivos subidos correctamente");
      } else {
        console.log(response.statusText);
      }
    })
    .catch(function (error) {
      console.log(error.message);
    });
}

function cambioRetBodega() {
  if (tipoSol.value.id == "Retiro Bodega") {
    for (var detRet of lstDetalle) {
      detRet.retiroBodega = true;
    }
  } else {
    for (var det of lstDetalle) {
      det.retiroBodega = false;
    }
  }
}

async function selectCompany() {
  try {
    if (localStorage.getItem("empresa") != null) {
      await swal
        .fire({
          icon: "warning",
          title: "Se perderán los datos ingresados, ¿Desea continuar?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          allowOutsideClick: false,
        })
        .then(async (result: any) => {
          if (result.isConfirmed) {
            isLoading.value = true;
            if (empresaSel.value != null) {
              localStorage.setItem("empresa", JSON.stringify(empresaSel.value));

              departamentoSel.value = null;
              vendorSel.value = null;
              solicitanteSel.value = null;
              //lstDetalle = [];

              await getDepartamentos(empresaSel.value.id);
              await getVendors(empresaSel.value.id);
              await getProyectos();
              await getCeco(empresaSel.value.id);
              await getBodegas(empresaSel.value.id);
            }
          } else {
            let jsonEmp = localStorage.getItem("empresa");
            if (jsonEmp != null) {
              empresaSel.value = JSON.parse(jsonEmp);
            }
          }
        });
    } else {
      isLoading.value = true;
      localStorage.setItem("empresa", JSON.stringify(empresaSel.value));

      if (empresaSel.value != null) {
        await getDepartamentos(empresaSel.value.id);
        await getVendors(empresaSel.value.id);
        await getProyectos();
        await getCeco(empresaSel.value.id);
        await getBodegas(empresaSel.value.id);
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function addFiles() {
  for (var f of selectedFiles.value) {
    file.value.push(f);
  }

  selectedFiles.value = [];
}

function getGUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function filterOnlyNumber(evt: any) {
  evt = evt ? evt : window.event;
  let expect = evt.target.value.toString() + evt.key.toString();

  //solo numeros
  if (!/^[0-9]*$/.test(expect)) {
    evt.preventDefault();
  } else {
    return true;
  }
}

function filterNumber(evt: any) {
  evt = evt ? evt : window.event;
  let expect = evt.target.value.toString() + evt.key.toString();

  if (!/^[0-9]*\.?[0-9]*$/.test(expect)) {
    evt.preventDefault();
  } else {
    return true;
  }
}

function calcularTotalCantidad(item: SolicitudDetalle) {
  // if (item == null || item.cantidad == null || item.precio == null) {
  //   return;
  // }

  //si item.precio contiene comas o puntos borrarselos
  item.precio = item.precio?.toString().replace(/,/g, "").replace(/\./g, "");
  item.cantidad = item.cantidad.toString().replace(/,/g, ".");

  item.total = parseFloat(item.cantidad) * parseFloat(item.precio);
  compraTotal();

  // var validPrecio = currency(item.precio, {
  //   separator: ".",
  //   decimal: ",",
  //   errorOnInvalid: true,
  // });

  // var validCantidad = currency(item.cantidad, {
  //   decimal: ",",
  //   errorOnInvalid: true,
  // });

  // if (!isNaN(validPrecio.value) && !isNaN(validCantidad.value)) {
  //   item.total = validCantidad.value * validPrecio.value;

  //   compraTotal();
  // } else {
  //   swal.fire({
  //     icon: "warning",
  //     title: "Formato Incorrecto",
  //     showConfirmButton: true,
  //   });
  // }

  // item.total =
  //   parseFloat(item.cantidad.toString().replace(",", ".")) *
  //   parseFloat(item.precio.toString().replace(",", "."));
  // compraTotal();
}

function formatoCantidad(item: SolicitudDetalle) {
  //si item.cantidad contiene una coma, reemplazarla por un punto
  item.cantidad = item.cantidad.toString().replace(/,/g, ".");
}

function compraTotal() {
  total.value = lstDetalle.reduce((acc, item) => acc + item.total, 0);
}

function rendicionTotal(item: SolicitudDetalle) {
  var validMonto = currency(item.monto, {
    separator: ".",
    decimal: ",",
    errorOnInvalid: true,
  });

  if (!isNaN(validMonto.value)) {
    item.monto = validMonto.value;
    total.value = lstDetalle.reduce(
      (acc, item) => acc + parseFloat(item.monto),
      0
    );
  } else {
    swal.fire({
      icon: "warning",
      title: "Formato Incorrecto",
      showConfirmButton: true,
    });
  }

  // this.total = this.lstDetalle.reduce(
  //   (acc, item) => acc + parseFloat(item.Monto.replace(",", ".")),
  //   0
  // );
}

function formatTotal(value: number | null): string {
  if (value == null) {
    return "";
  }
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
}

function showDeleteDialog(item: SolicitudDetalle) {
  itemToDelete = item;
  dialogDelete.value = !dialogDelete.value;
}

function deleteMaterial(item: SolicitudDetalle) {
  //const index = lstDetalle.values.indexOf(item);
  let index = lstDetalle.findIndex((x) => x.id == item.id);
  lstDetalle.splice(index, 1);

  dialogDelete.value = !dialogDelete;

  for (let i = 0; i < lstDetalle.length; i++) {
    lstDetalle[i].id = i + 1;
  }

  detContador = lstDetalle.length + 1;
}
</script>
